<template>
  <ul class="partners-list">
    <li
      v-for="{ name, link, image, className } in partners"
      :key="name"
      class="partners-item"
    >
      <a class="partners-link" :href="link" rel="nofollow" target="_blank"
        ><img
          class="partners-img"
          :class="`partners-img--${className}`"
          :src="image"
          :alt="name"
      /></a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Partners",
  data: () => ({
    partners: [
      {
        name: "You Team",
        className: "you-team",
        link: "https://youteam.co.uk/partner/rademade?ref=rademade",
        image: require("@/assets/images/partners/youteam.png")
      },
      {
        name: "Clutch",
        className: "clutch",
        link: "https://clutch.co/profile/iternal-group",
        image: require("@/assets/images/partners/clutch.png")
      },
      {
        name: "Top Web-development Companies",
        className: "topdevelopers",
        link: "https://www.itfirms.co/top-web-development-companies/",
        image:
          "https://www.itfirms.co/wp-content/uploads/2020/12/web-developer-itfirms-2021.png"
      },
      {
        name: "Top Mobile App Development Companies",
        className: "topdevelopers",
        link: "https://www.itfirms.co/top-mobile-app-development-companies/",
        image:
          "https://www.itfirms.co/wp-content/uploads/2020/12/app-developer-itfirms-2021.png"
      },
      {
        name: "Designrush",
        className: "designrush",
        link:
          "https://www.designrush.com/agency/offshore-software-developers/ua",
        image: require("@/assets/images/partners/design-rush.png")
      },
      {
        name: "Goodfirms",
        className: "goodfirms",
        link: "https://www.goodfirms.co/company/iternal-group ",
        image: require("@/assets/images/partners/good-firms.png")
      }
    ]
  })
};
</script>

<style lang="scss">
.partners {
  background: #f9f9f9;
  border-bottom: 1px solid #e7e7e7;
}
.partners-list {
  display: flex;
  padding: 50px 0;
  margin: 0 -10px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: $viewport-tablet) {
    flex-wrap: wrap;
    margin: 0 -40px;
    padding: 30px 0;
    justify-content: center;
  }
  @media (max-width: $viewport-mobile) {
    margin: 0 -10px;
    justify-content: space-around;
  }
}
.partners-item {
  display: block;
  padding: 0 10px;
  @media (max-width: $viewport-tablet) {
    padding: 20px 40px;
  }
  @media (max-width: $viewport-mobile) {
    padding: 10px;
  }
}
.partners-link {
  position: relative;
  display: block;
  @media (max-width: $viewport-tablet) {
    max-width: 125px;
  }
  &.mod-clutch {
    max-width: 100%;
  }
  &:hover {
    .partners-img {
      filter: grayscale(0);
    }
  }
}
.partners-img {
  display: block;
  width: 100%;
  height: auto;
  filter: grayscale(100%);
  transform: translateZ(0);
  transition: filter 0.3s ease;
}
.partners-img--goodfirms {
  max-width: 170px;
  height: auto;
}
.partners-img--designrush {
  max-width: 230px;
  height: auto;
}
.partners-img--clutch {
  max-width: 140px;
}
.partners-img--topdevelopers {
  max-width: 125px;
}
</style>
