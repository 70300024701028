<template>
  <div class="footer-contact-data">
    <div class="footer-info-data">
      <ul class="social-links-list" v-if="data.social && data.social.length">
        <li
          class="social-link-item social-links-list__item"
          v-for="item in data.social"
          :key="item.link"
        >
          <a
            :href="item.link"
            target="_blank"
            rel="noopener"
            class="social-link-item__link"
          >
            <v-dynamic-icon
              v-if="item.icon"
              :icon="item.icon"
              class="social-link-item__link-img"
            >
            </v-dynamic-icon>
          </a>
        </li>
      </ul>
    </div>
    <div class="footer-contact-data-item-list" id="contact-info">
      <div
        class="footer-contact-data-item footer-contact-data-item-list__item"
        v-for="item in data.contacts"
        :key="item.title"
      >
        <span
          class="footer-contact-data-item__title footer-contact-data-item-title"
        >
          {{ item.title }}
          <v-dynamic-icon
            v-if="item.icon"
            :icon="item.icon"
            class="footer-contact-data-item-title__img"
          >
          </v-dynamic-icon>
        </span>
        <a
          :href="'tel:' + item.phone"
          class="footer-contact-data-item__text"
          v-if="item.phone"
          >{{ item.phone }}</a
        >
        <a
          :href="'mailto:' + item.email"
          class="footer-contact-data-item__text"
          v-if="item.email"
          >{{ item.email }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer-contact-data",
  props: {
    data: Object
  }
};
</script>

<style lang="scss">
.footer-contact-data {
  display: flex;
  margin-bottom: 80px;

  @media (max-width: $viewport-tablet) {
    margin-bottom: 30px;
  }

  @media (max-width: $viewport-tablet-small) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    border-top: 1px solid #2a2a2a;
  }
}

.footer-info-data {
  width: 20%;

  @media (max-width: $viewport-tablet-small) {
    width: 100%;
    //margin-bottom: 30px;
  }
}

.social-links-list {
  display: flex;
  margin-bottom: 30px;
  padding-top: 50px;

  @media (max-width: $viewport-tablet-small) {
    margin-bottom: 20px;
    padding-top: 30px;
  }
  @media (max-width: $viewport-mobile) {
    justify-content: center;
  }
}

.social-link-item {
  margin-right: 28px;

  &:last-child {
    margin-right: 0;
  }
}

.social-link-item__link {
  display: block;
}

.social-link-item__link-img {
  display: block;
  width: 26px;
  height: 26px;
}

.footer-info-data-text__item {
  display: block;
  color: #888;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 2.166666667;
}

.footer-contact-data-item-list {
  display: flex;
  justify-content: space-between;
  width: 80%;
  padding-top: 55px;
  margin-left: -16px;
  border-top: 1px solid #2a2a2a;
  box-sizing: border-box;

  @media (max-width: $viewport-tablet) {
    flex-wrap: wrap;
  }

  @media (max-width: $viewport-tablet-small) {
    width: 100%;
    border-top: none;
  }

  @media (max-width: $viewport-mobile) {
    padding-top: 30px;
  }
  @media (max-width: 440px) {
    margin-left: 0;
  }
}

.footer-contact-data-item {
  padding-left: 16px;
  box-sizing: border-box;

  &:first-child {
    padding-left: 0;

    @media (max-width: $viewport-tablet) {
      padding-left: 16px;
    }
  }

  @media (max-width: $viewport-tablet) {
    width: 50%;
    margin-bottom: 40px;
  }
  @media (max-width: 440px) {
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
}

.footer-contact-data-item__title {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  color: #ffffff;

  @media (max-width: $viewport-tablet) {
    margin-bottom: 15px;
  }

  @media (max-width: 440px) {
    justify-content: center;
  }
}

.footer-contact-data-item-title__img {
  width: 24px;
  height: 16px;
  margin-left: 10px;
}

.footer-contact-data-item__text {
  display: block;
  margin-bottom: 10px;
  color: #888;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 2.166666667;
  transition: color 0.15s ease-in-out;

  @media (max-width: $viewport-mobile) {
    margin-bottom: 0;
  }

  &:hover {
    color: #fff;
  }
}
</style>
