<template>
  <div class="mobile-menu" :class="{ 'mobile-menu--open': mobileMenuOpen }">
    <v-navigation type="mobile-menu"></v-navigation>
    <button class="header-get-btn header-get-btn--mobile" @click.stop="openGetQuoteModal">Get a quote</button>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation/Navigation";
import { eventBus } from "@/main.js"

export default {
  name: "Mobile-menu",
  props: {
    mobileMenuOpen: Boolean,
  },
  components: {
    "v-navigation": Navigation,
  },
  methods: {
      openGetQuoteModal(){
        eventBus.$emit('open-get-quote-modal')
    }
  }
};
</script>

<style lang="scss">
.mobile-menu {
  position: fixed;
  top: 0;
  right: -250px;
  height: 100vh;
  width: 250px;
  background: #000;
  z-index: 2;
  padding: 90px 30px 30px;
  transition: transform 0.3s ease-in-out;
  overflow: auto;
  box-sizing: border-box;

  .main-menu {
    display: block;
  }

  &--open {
    transform: translateX(-250px);
  }

  .mobile-menu__menu {
    display: block;
  }

  .main-menu__item {
    margin-bottom: 10px;
  }

  .main-menu__item-link {
    display: block;

    &:hover {
      color: #fff;
    }
  }
  .header-get-btn {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
    text-align: center;
    @media (max-width: $viewport-desktop) {
      display: block;
    }
  }
}
</style>