export default class Helper {

    static objectNotEmpty(object) {
        return !!object && Object.keys(object).length
    }

    static arrayNotEmpty(array) {
        return !!array && array.length
    }

}
