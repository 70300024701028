import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/key-clients',
    name: 'Key-clients',
    component: () => import(/* webpackChunkName: "key-clients" */ '../views/Key-clients.vue')
  },
  {
    path: '/cookies',
    name: 'Cookies',
    component: () => import(/* webpackChunkName: "key-clients" */ '../views/Cookies.vue')
  },
  {
    path: '/term-of-use',
    name: 'Term-of-use',
    component: () => import(/* webpackChunkName: "key-clients" */ '../views/TermOfUse.vue')
  },
  {
    path: '/privacy-notice',
    name: 'Privacy-notice',
    component: () => import(/* webpackChunkName: "key-clients" */ '../views/PrivacyNotice.vue')
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import(/* webpackChunkName: "services" */ '../views/Services.vue')
  },
  {
    path: '/success-stories',
    name: 'Success-stories',
    component: () => import(/* webpackChunkName: "success-stories" */ '../views/Success-stories.vue')
  },
  {
    path: '/success-stories-:slug',
    name: 'Success-story',
    component: () => import(/* webpackChunkName: "success-stories" */ '../views/Success-stories.vue')
  },
  {
    path: '/careers',
    name: 'Careers',
    component: () => import(/* webpackChunkName: "careers" */ '../views/Careers.vue')
  },
  {
    path: '/careers/:slug',
    name: 'Careers-single',
    component: () => import(/* webpackChunkName: "careers-single" */ '../views/Careers-single.vue')
  },
  {
    path: '/contacts',
    name: 'Contacts',
    meta: {
      footerWithoutHeader: true,
      footerMainInfo: true,
    },
    component: () => import(/* webpackChunkName: "contacts" */ '../views/Contacts.vue'),
  },
  {
    path: "*",
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "PageNotFound" */ '../views/PageNotFound'),
    meta: {
      footerWithoutHeader: true,
      footerWithoutActions: true
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.name === "Success-story" && to.params.slug) {
      return { selector: '#success-story-tab', offset: { x: 0, y: 136 }};
    }
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

export default router
