<template>
  <v-fixed-header :headerClass="'header'" :fixedClass="'header--sticky'" :threshold="50">
    <header id="header">
      <div class="header__box">
        <div class="wrapper">
          <div class="header__container">
            <router-link to="/" class="logo header__logo">
              <v-logo class="header__logo-img" viewBox="0 0 183 76"/>
            </router-link>
            <v-navigation class="header__menu"></v-navigation>
            <div v-click-outside="onClickOutside">
              <button class="header__mobile-menu-btn" :class="{'header__mobile-menu-btn--open': mobileMenuOpen}" @click="toggleMobileMenu">
                <span></span><span></span><span></span><span></span>
              </button>
              <button class="header-get-btn" @click.stop="openGetQuoteModal">Get a quote</button>
              <v-mobile-menu :mobile-menu-open="mobileMenuOpen"></v-mobile-menu>
            </div>
          </div>
        </div>
      </div>
    </header>
  </v-fixed-header>
</template>

<script>
//Icons
import IconLogo from '@/assets/icons/logo.svg'
//Components
import Navigation from '@/components/Navigation/Navigation'
import FixedHeader from 'vue-fixed-header'
import MobileMenu from '@/components/Header/Mobile-menu'
//Plugins
import vClickOutside from 'v-click-outside'
import { eventBus } from "@/main";

export default {
  name: "Header",
  components: {
    'v-logo': IconLogo,
    'v-navigation': Navigation,
    'v-fixed-header': FixedHeader,
    'v-mobile-menu': MobileMenu
  },
  data() {
    return {
      mobileMenuOpen: false
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
    },
    onClickOutside() {
      this.mobileMenuOpen = false
    },
    openGetQuoteModal(){
      eventBus.$emit('open-get-quote-modal');
      this.pushGa();
    },
    pushGa() {
      this.$ga.event({
        eventCategory: 'button',
        eventAction: 'click',
        eventLabel: 'quote'
      })
    }
  },
  mounted() {
    eventBus.$on('clickOnMenu', () => this.onClickOutside());
  }
}
</script>

<style lang="scss">
.header {
  position: relative;
  padding-top: 50px;
  height: 116px;
  background: #000;

  @media (max-width: $viewport-mobile) {
    height: 90px;
    padding-top: 30px;
  }
}

.header__box {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  background-color: #000;
  z-index: 2;

  @media (max-width: $viewport-mobile) {
    top: 30px;
  }
}

.header--sticky {

  .header__box {
    position: fixed;
    top: 0;
    background-color: #fff;
    transition: background-color .2s ease-in-out, border-bottom-color .2s ease-in-out;
    border-bottom: 1px solid #f1f1f1;
    box-sizing: border-box;
  }

  .header__logo-img {
    fill: #000;
  }

  .header-get-btn {
    background: var(--primary-red-color);

    &:hover {
      color: #fff;
      background: #b42400;
    }
  }

  .main-menu__item-link {
    color: #808080;

    &:hover {
      color: #000;
    }
  }

  .main-menu__item-link--current {
    color: #000;
  }

  .mobile-menu {

    .main-menu__item-link--current {
      color: #fff;
    }
  }

  + .section-box {
    padding-top: 166px;

    @media (max-width: $viewport-mobile) {
      padding-top: 145px;
    }
  }
}

.header__container {
  display: flex;
  align-items: center;
  padding: 20px 0;

  @media (max-width: $viewport-desktop) {
    justify-content: space-between;
  }

  @media (max-width: $viewport-mobile) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.header__logo {
  margin-right: 30px;

  @media (max-width: $viewport-desktop) {
    flex-grow: 1;
  }
}

.header__logo-img {
  display: block;
  width: 183px;
  max-width: 100%;
  height: 76px;
  fill: #fff;

  @media (max-width: $viewport-mobile) {
    width: 140px;
    height: 59px;
  }
}

.main-menu.header__menu {

  @media (max-width: $viewport-desktop) {
    display: none;
  }
}

.header-get-btn {
  display: inline-block;
  padding: 10px 20px;
  margin-left: 45px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: .75rem;
  color: #fff;
  white-space: nowrap;
  background: #1c1c1c;
  border-radius: 70px;
  transition: all .15s ease-in-out;


  @media (max-width: $viewport-desktop) {
    display: none;
  }

  &:hover {
    background: #fff;
    color: #1c1c1c;
  }
}

.header__mobile-menu-btn {
  position: relative;
  z-index: 3;
  display: none;
  width: 34px;
  height: 31px;
  margin-top: 9px;
  font-size: 0;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;

  @media (max-width: $viewport-desktop) {
    display: block;
  }

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: var(--primary-red-color);
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 9px;
    }

    &:nth-child(4) {
      top: 18px;
    }
  }

  &--open {

    span {

      &:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
      }
    }
  }
}
</style>
