<template>
  <div>
    <h2 class="section-title section-title--centered" v-if="data.title" v-html=" data.title"></h2>
    <div v-if="data.description" v-html="data.description"></div>

    <div v-if="data.itMarketInfo">
      <p class="it-market-info-title">{{ data.itMarketInfo.title }}</p>
      <div class="it-market-info" v-if="data.itMarketInfo.list && data.itMarketInfo.list.length">
        <div class="it-market-info__item" v-for="item in data.itMarketInfo.list" :key="item.title">
          <p class="it-market-info__item-title">{{ item.title }}</p>
          <div class="it-market-chart" v-if="item.chart && item.chart.length">
            <div class="it-market-chart__item" v-for="chartItem in item.chart" :key="chartItem.description" :style="`height: ${chartItem.value}%`">
              <span class="it-market-chart__item-percentage">{{ chartItem.value }}</span>
              <span class="it-market-chart__item-underwrite">{{ chartItem.description }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "Market-info-block",
  props: {
    data: Object
  }
}
</script>

<style lang="scss">
.it-market-info-title {
  margin: 150px 0 110px;
  font-family: Visuelt Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 2.125rem;
  line-height: 1.117647059;
  text-align: center;
  color: #000000;

  @media (max-width: $viewport-tablet) {
    font-size: 1.75rem;
    margin: 100px 0 80px;
  }

  @media (max-width: $viewport-mobile) {
    margin: 60px 0 40px;
  }
}

.it-market-info {
  display: flex;
  justify-content: space-around;
  margin-bottom: 100px;
  text-align: center;

  @media (max-width: $viewport-tablet) {
    margin-bottom: 60px;
  }

  @media (max-width: $viewport-mobile) {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
}

.it-market-info__item {
  width: 33.333%;
  padding: 0 50px;
  box-sizing: border-box;

  @media (max-width: $viewport-tablet-small) {
    padding: 0 25px;
  }

  @media (max-width: $viewport-mobile) {
    width: 100%;
    margin-bottom: 70px;
  }

  &:last-child {
    @media (max-width: $viewport-mobile) {
      margin-bottom: 0;
    }
  }
}

.it-market-info__item-title {
  font-style: normal;
  font-weight: bold;
  font-size: 0.6875rem;
  line-height: 1.181818182;
  text-transform: uppercase;
  color: #000000;
}

.it-market-chart {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100px;
}

.it-market-chart__item {
  position: relative;
  width: 36px;
  margin-right: 5px;
  background: #FC351D;

  &:last-child {
    margin-right: 0;
  }
}

.it-market-chart__item-percentage {
  position: absolute;
  top: -15px;
  left: 0;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 0.5625rem;
  line-height: 1.222222222;
  text-align: center;
  color: var(--primary-red-color);
}

.it-market-chart__item-underwrite {
  position: absolute;
  bottom: -12px;
  left: 0;
  width: 100%;
  font-style: normal;
  font-size: 0.5625rem;
  line-height: 1.222222222;
  text-align: center;
  color: #999999;
}

</style>
