<template>
  <div>
    <h2 class="section-title">{{ data.title }}</h2>
    <ul class="advantages-list" v-if="data && data.list && data.list.length">
      <li class="advantages-list-item advantages-list__item" v-for="item in data.list" :key="item.title">
        <span class="advantages-list-item__title" v-if="item.title">{{ item.title }}</span>
        <span class="advantages-list-item__description" v-if="item.description">{{ item.description }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Advantages",
  props: {
    data: Object
  }
}
</script>

<style lang="scss">
.advantages-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 1100px;
  margin-top: 100px;
  padding-bottom: 30px;

  @media (max-width: $viewport-tablet) {
    max-width: 800px;
    margin-top: 80px;
  }

  @media (max-width: $viewport-mobile) {
    margin-top: 50px;
  }
}

.advantages-list__item {

}

.advantages-list-item {
  width: 50%;
  margin-bottom: 80px;
  padding-right: 50px;
  box-sizing: border-box;

  @media (max-width: $viewport-tablet) {
    margin-bottom: 60px;
  }

  @media (max-width: $viewport-mobile) {
    width: 100%;
    margin-bottom: 40px;
  }
}

.advantages-list-item__title {
  display: block;
  font-family: Visuelt Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 2.125rem;
  line-height: 1.117647059;
  color: #000000;

  @media (max-width: $viewport-tablet) {
    font-size: 1.875rem;
  }
}

.advantages-list-item__description {
  display: block;
  margin-top: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.8;
  color: #000000;
}

</style>
