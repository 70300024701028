import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'

import "./assets/scss/base/_import.scss";
import 'swiper/swiper.scss';

Vue.config.productionTip = false

//Vue Meta
import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

//Axios
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

// V-click-outside
import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside)

//Event Bus
export const eventBus = new Vue()

//Global Dynamic Icon component
import DynamicIcon from '@/components/Dynamic-icon'
Vue.component('v-dynamic-icon', DynamicIcon);

//File Agent
import VueFileAgent from 'vue-file-agent';
import 'vue-file-agent/dist/vue-file-agent.css';
Vue.use(VueFileAgent);

//Vuelidate
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueAnalytics from 'vue-analytics'
Vue.use(VueAnalytics, {
  id: 'UA-167524344-1'
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
