<template>
  <div class="get-quote-step__controls get-quote-step-controls">
    <div class="get-quote-step-controls-item get-quote-step-controls-item--left">
      <button
          class="get-quote-step-controls__btn get-quote-step-controls__btn--prev"
          type="button"
          v-if="false"
          @click.stop="previousStep">Back
        </button>
    </div>
    <div v-if="false" class="get-quote-step-controls__text get-quote-step-controls-item">Step {{ currentStep }}
      <span class="get-quote-step-controls__text get-quote-step-controls__text--highlighted">&nbsp;of {{ totalSteps }}</span>
    </div>
    <div class="get-quote-step-controls-item get-quote-step-controls-item--right">
      <button
          class="get-quote-step-controls__btn get-quote-step-controls__btn--next"
          :class="{'get-quote-step-controls__btn--active': nextActive}"
          :disabled="!nextActive" @click.stop="nextStep">
          Send a request
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Controls",
  props: {
    totalSteps: [Number, String],
    currentStep: [Number, String],
    nextActive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    nextStep() {
      this.$emit('go-to-next-step')
    },
    previousStep() {
      this.$emit('go-to-previous-step')
    }
  }
}
</script>

<style lang="scss">
.get-quote-step-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // @media (max-width: $viewport-mobile) {
  //   flex-direction: column-reverse;
  // }
}

.get-quote-step-controls-item {
  width: calc(100% / 3);

  @media (max-width: $viewport-mobile) {
    width: calc(50% - 8px);
  }

  &--left {
    text-align: left;
  }
  &--right {
    text-align: right;
    @media (max-width: $viewport-mobile) {
      width: calc(60% - 8px);
    }
  }
}

.get-quote-step-controls__btn {
  position: relative;
  width: 320px;
  padding: 15px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 0.9375rem;
  color: #ac9191;
  box-sizing: border-box;
  opacity: .3;
  cursor: pointer;

  @media (max-width: $viewport-tablet) {
    width: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    width: 13px;
    height: 13px;
    background-image: url($arrowIcon);
    background-repeat: no-repeat;
    background-position: center;
    transform: translateY(-50%);
  }

  &:disabled {
    cursor: default;
  }

  &--active {
    position: relative;
    color: #000000;
    background: #fff;
    box-shadow: 0px 40px 64px #EBEBEB;
    opacity: 1;

    &:hover {

      &:before {
        background-color: #FF3500;
      }
    }

    &:before {
      content: "";
      position: absolute;
      bottom: -15px;
      left: 0;
      width: 100%;
      height: 5px;
      background-color: transparent;
      transition: background-color .2s ease-in-out;

      @media (max-width: $viewport-mobile) {
        display: none;
      }
    }
  }

  &--prev {
    text-align: right;

    &:after {
      left: 28px;
      transform: rotate(180deg) translateY(50%);
      @media (max-width: $viewport-mobile) {
        left: 15px;
      }
    }
  }

  &--next {
    text-align: left;

    &:after {
      right: 28px;
      @media (max-width: $viewport-mobile) {
        right: 15px;
      }
    }
  }
}

.get-quote-step-controls__text {
  font-family: "Visuelt Pro";
  font-weight: 500;
  font-size: 1.25rem;
  text-align: center;

  @media (max-width: $viewport-mobile) {
    display: none;
  }

  &--highlighted {
    color: #a9a9a9;
  }
}
</style>
