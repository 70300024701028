<template>
  <div class="modal">
    <div class="modal-overlay">
      <div class="modal-box" v-click-outside="closeModal">
        <button class="modal-box__close" type="button" aria-label="Close modal" @click="closeModal">
          <v-icon-close class="modal-box__close-icon"/>
        </button>
        <div class="modal-box__content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconClose from '@/assets/icons/close.svg'
import ClickOutside from 'vue-click-outside'

export default {
  name: "Modal",
  props: {
  },
  components: {
    'v-icon-close': IconClose
  },
  directives: {
    ClickOutside
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
  },
  mounted() {
    // Close modal with 'esc' key
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
          this.closeModal();
      }
    });
},
}
</script>

<style lang="scss">
.modal {
  position: fixed;
  z-index: 1000000;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
}

.modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  height: auto;
  padding: 50px 30px;
  box-sizing: border-box;

  @media(max-width: $viewport-desktop-large) {
    padding: 15px 30px;
  }

  @media (max-width: $viewport-mobile) {
    padding: 0;
  }
}

.modal-box {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  min-height: 488px;
  padding: 20px 20px 15px 20px;
  background: #F1F1F1;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media (max-width: $viewport-mobile) {
    padding: 15px;
  }
}

.modal-box__close {
  position: absolute;
  width: 26px;
  height: 26px;
  top: 23px;
  right: 15px;
  font-size: 0;
  cursor: pointer;
  fill: #1D1D1B;
  transition: opacity .2s ease-in-out;

  &:hover {
    opacity: .5;
  }
}

.modal-box__close-icon {
  width: 26px;
  height: 26px;
}
</style>
