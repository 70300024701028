<template>
  <div>
    <h2 class="section-title section-title--centered" v-if="data.title" v-html="data.title"></h2>
    <p class="section-description-row section-description-row--centered" v-if="data.text">{{ data.text }}</p>
    <div class="section-link-container" v-if="data.link">
      <a :href="data.link.href" class="section-link section-link-container__link">{{ data.link.text }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "About-block",
  props: {
    data: Object
  }
}
</script>

<style lang="scss">

</style>
