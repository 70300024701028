<template>
  <div class="content">
    <section class="section-box section-box--black section-box--first">
      <v-main-info-banner />
      <div class="wrapper">
        <v-main-info :data="mainInfoData"></v-main-info>
      </div>
    </section>
    <section class="section-box" id="about">
      <div class="wrapper">
        <v-about-block :data="aboutBlockData"></v-about-block>
      </div>
    </section>
    <section class="section-box section-box--highlighted" id="services">
      <div class="wrapper">
        <v-services-block></v-services-block>
      </div>
    </section>
    <section class="section-box" id="expertise">
      <div class="wrapper">
        <v-expertise-block :data="expertiseBlock"></v-expertise-block>
      </div>
    </section>
    <section
      class="section-box section-box--highlighted section-box--ukrainebg"
      id="advantages"
    >
      <div class="wrapper">
        <v-advantages-block :data="advantagesBlock"></v-advantages-block>
      </div>
    </section>

    <!-- <section class="section-box section-box--no-bot-padding" id="clients">
      <v-clients-map></v-clients-map>
    </section> -->
    <section class="section-box">
      <div class="wrapper">
        <v-partners />
      </div>
    </section>

    <section class="section-box section-box--highlighted" id="reviews">
      <div class="wrapper">
        <v-reviews-block></v-reviews-block>
      </div>
    </section>

    <section class="section-box">
      <div class="wrapper">
        <v-market-info-block :data="marketInfoBlockData"></v-market-info-block>
      </div>
    </section>
  </div>
</template>

<script>
//components
import MainInfo from "@/components/layout/Main-info/Main-info";
import AboutBlock from "@/components/layout/About/About-block";
import ServicesBlock from "@/components/layout/Services/Services-block";
import ExpertiseBlock from "@/components/layout/Expertise/Expertise-block";
import AdvantagesBlock from "@/components/layout/Advantages/Advantages-block";
import ReviewsBlock from "@/components/layout/Reviews/Reviews-block";
//import ClientsMap from '@/components/layout/Clients-map/Clients-map'
import MarketInfoBlock from "@/components/layout/Market-info/Market-info-block";
import Partners from "@/components/Partners";
import MainInfoBanner from "@/components/layout/Main-info/MainInfoBanner.vue";

export default {
  name: "Home",
  metaInfo: {
    title: "ITernal Group – IT vendor for complex software solutions"
  },
  data() {
    return {
      mainInfoData: {
        title:
          '<span class="main-info-title__row">ITernal Group</span><span class="main-info-title__row">Your reliable IT vendor for</span><span class="main-info-title__row main-info-title__row--highlighted">complex software solutions</span>',
        subTitle:
          "We are troubleshooters focused on overcoming the most challenging issues in software development and IT outsourcing.",
        link: {
          text: "Discuss your project",
          href: "#contact-info",
          description:
            "We care about your users, profit and growth. We are ready to share our expertise."
        }
      },
      aboutBlockData: {
        title:
          'A one-stop partner <span class="section-title__text-highlighted">into the future</span>',
        text:
          "The story of ITernal Group started in 2019 with a merger of three IT outsource companies. The oldest of them was founded in 2004. We have wide expertise in more than 20 industries: adtech, martech, fintech, ERP, transport, logistics, ecommerce, and more. This offers great potential to companies that work with us. With our help, you can improve an existing software, scale fast or launch R&D centers with minimal efforts."
      },
      expertiseBlock: {
        title: "We help companies to move forward faster",
        subTitle:
          "We are focused on eliminating all the pains that accompany any business through a lifespan. That’s why we dive deep to find and remove any blockers, to help our clients make an impact on markets and reach their business goals.",
        infoCelledTitle: "Help companies grow and prosper",
        infoCelled: [
          {
            title: "Technology expertise for IT companies",
            description:
              "Prevent bottlenecks and set the wheels of the prolonged development process in motion. To meet deadlines, we have a great talents and can handle the whole project by our team.",
            itemList: [
              { text: "Move and scale faster" },
              { text: "Prioritize tasks and feature sets" },
              { text: "Find the right tools" },
              { text: "Straighten the development flow" }
            ]
          },
          {
            title: "Software development from scratch",
            description:
              "Get a ready-to-sell software in time. With ITernal, you get access to multi-industry expertise that helps to avoid the main mistakes when launching a new business.",
            itemList: [
              { text: "Launch a new business without stress" },
              { text: "Get an assembled team of skilled software engineers" },
              { text: "Stick with proven technology stacks" },
              { text: "Build a software and improve it with every iteration" }
            ]
          },
          {
            title: "R&D for non-residents",
            description:
              "Go test drive before opening your own R&D center in Ukraine. Benefit from expert advice on legal and recruitment to avoid unnecessary risks or set up a company on a turn-key basis.",
            itemList: [
              {
                text: "Get access to the largest tech talents pool in Ukraine"
              },
              { text: "Minimize risks and financial loss" },
              { text: "Low and simple tax rates" },
              { text: "Launch R&D in 2 months" }
            ]
          }
        ],
        marketExpertiseTitle: "Market expertise at",
        marketExpertise: [
          {
            title: "HealthCare",
            text:
              "We develop high-performance software solutions helping healthcare companies to drive industry transformations and utilize the latest digital technologies.",
            icon: "expertise-healthcare"
          },
          {
            title: "Marketplaces",
            text:
              "Hosted, serviced solution, scalable architecture, low latency, cloud-enabled features. Custom development of proprietary platforms, infrastructures, systems. Reduced Cost.",
            icon: "expertise-trading"
          },
          {
            title: "FinTech",
            text:
              "Experienced Fintech Developers that can help you with your Digital Transformation. Successful track record with People, Process, technology and Culture. Fintech. Near-zero Defect Software.",
            icon: "expertise-fintech"
          }
        ]
      },
      advantagesBlock: {
        title: "Why work with ITernal",
        list: [
          {
            title: "Proven experience",
            description: "16-year expertise in software development"
          },
          {
            title: "Strong engineers",
            description: "Senior-level professionals with 5 years average"
          },
          {
            title: "Fast scaling",
            description: "2 month to launch, terminate with a 2-week notice"
          },
          {
            title: "Dedicated manager",
            description:
              "You get a personal supervisor who closely follows your project"
          },
          {
            title: "Risk free agreements",
            description:
              "Contract with EU or US entity, IP belongs to your company"
          },
          {
            title: "Low taxes",
            description: "Income tax 5% included in the rate"
          }
        ]
      },
      marketInfoBlockData: {
        title:
          '<span class="section-title__highlighted">Grow faster</span> with the best tech talents',
        description:
          '<p class="section-description-row section-description-row--centered">Booming tech ecosystem, large talent pool, and a strong education made Ukraine a perfect place to start IT outsourcing. Here, international tech giants can hire high-skilled developers that have solid technical expertise, high English proficiency level, and knowledge of emerging disciplines.</p>' +
          '<p class="section-description-row section-description-row--centered">ITernal helps to handpick and hire IT specialists for remote teams that integrate painlessly or work independently.</p>',
        itMarketInfo: {
          title: "Ukrainian IT market",
          list: [
            {
              title: "Age",
              chart: [
                { description: "<20", value: "27" },
                { description: "21-30", value: "36" },
                { description: "31-40", value: "19" },
                { description: ">40", value: "11" }
              ]
            },
            {
              title: "Experience (years)",
              chart: [
                { description: "<1", value: "13" },
                { description: "1-2", value: "25" },
                { description: "3-5", value: "29" },
                { description: "6-10", value: "17" },
                { description: ">10", value: "12" }
              ]
            },
            {
              title: "Qualification level",
              chart: [
                { description: "Junior", value: "8" },
                { description: "Middle", value: "63" },
                { description: "Senior", value: "25" },
                { description: "Lead", value: "3" }
              ]
            }
          ]
        }
      }
    };
  },
  components: {
    "v-main-info": MainInfo,
    "v-about-block": AboutBlock,
    "v-services-block": ServicesBlock,
    "v-expertise-block": ExpertiseBlock,
    "v-advantages-block": AdvantagesBlock,
    "v-reviews-block": ReviewsBlock,
    //'v-clients-map': ClientsMap,
    "v-market-info-block": MarketInfoBlock,
    "v-partners": Partners,
    "v-main-info-banner": MainInfoBanner,
}
};
</script>
