<template>
  <div class="get-quote-step__title-wrap" v-if="title || subTitle">
    <p class="get-quote-step__title" v-if="title">{{ title }}</p>
    <span class="get-quote-step-title__underwrite" v-if="subTitle">{{ subTitle }}</span>
  </div>
</template>

<script>
export default {
  name: "Title",
  props: {
    title: String,
    subTitle: String
  }
}
</script>

<style lang="scss">
.get-quote-step__title-wrap {
  padding-right: 70px;
  padding-bottom: 15px;
  border-bottom: 1px solid #d9d9d9;
}

.get-quote-step__title {
  font-size: 2rem;
  font-family: Visuelt Pro;
  font-weight: 500;
}

.get-quote-step-title__underwrite {
  display: block;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.625;
}
</style>
