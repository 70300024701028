<template>
  <div class="main-info-banner">
    <div class="main-info-banner__wrapper wrapper">
      <div class="main-info-banner__content">
        <div class="main-info-banner__box main-info-banner__box--logo">
          <div class="main-info-banner__logo-wrapper">
            <UstLogo class="main-info-banner__logo" />
          </div>
        </div>

        <div class="main-info-banner__list">
          <div class="main-info-banner__box main-info-banner__box--text">
            <p class="main-info-banner__text">Since January of 2022 ITernal Group became a part of UST – Digital Transformation Solutions Company</p>
          </div>
          <div class="main-info-banner__box main-info-banner__box--button">
            <a href="https://www.ust.com/" class="main-info-banner__button main-info-banner-button" target="_blank">
              <span class="main-info-banner-button__text">Learn about UST</span>
              <span class="main-info-banner-button__icon-wrapper">
                <ArrowRightIcon class="main-info-banner-button__icon" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import UstLogo from '@/assets/images/ust.svg';
  import ArrowRightIcon from '@/assets/icons/arrow-right.svg';

  export default {
    components: {
      UstLogo,
      ArrowRightIcon,
    }
  }
</script>
<style lang="scss" scoped>
  .main-info-banner {
    color: #fff; 
    font-weight: 400;
    font-size: 14px;
    line-height: 2;
    color: rgba(255,255,255,.5);
    @media (min-width: $viewport-mobile) {
      line-height: 2.8;
    }
  }

  .main-info-banner__content {
    display: flex;
    padding: 30px 15px;
    background: rgba(255,255,255, .1);
  }

  .main-info-banner__box {
    padding: 0 15px;
  }

  .main-info-banner__box--logo {
    position: relative;
  }

  .main-info-banner__box--text {
    flex: 1;
    margin-bottom: 15px;

    @media (min-width: $viewport-mobile) {
      margin-bottom: 0;
    }
  }

  .main-info-banner__box--button {
    display: flex;
    justify-content: flex-end;
  }

  .main-info-banner__list {
    flex: 1;
    align-items: center;

    @media (min-width: $viewport-mobile) {
      display: flex;
    }
  }

  .main-info-banner__logo-wrapper {
    position: relative;
    width: 54px;
    height: 100%;
    min-height: 59px;
  }

  .main-info-banner__logo {
    position: absolute;
    display: block;
    top: 10px;
    left: 0;
    width: 54px;
    height: 59px;

    @media (min-width: $viewport-mobile) {
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .main-info-banner-button {
    position: relative;
    padding-right: 55px;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    line-height: 38px;

    &:hover {
      .main-info-banner-button__text {
        text-decoration: underline;
      }
    }
  }

  .main-info-banner-button__icon-wrapper {
    position: absolute;
    top: 50%;
    right: 0;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.05);
    transform: translateY(-50%);
  }

  .main-info-banner-button__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 13px;
    height: 10px;
    transform: translateX(-50%) translateY(-50%);
  }
</style>
