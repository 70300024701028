<template>
  <ul class="main-menu">
    <li class="main-menu__item" v-for="item in navigation" :key="item.name">
      <router-link
          :to="{'name': item.name}"
          exact
          class="main-menu__item-link"
          active-class="main-menu__item-link--current">
        <span class="main-menu__item-text" @click="onMenuClick">{{ item.text }}</span>
      </router-link>
    </li>
  </ul>
</template>

<script>
import { eventBus } from '@/main'

export default {
  name: "Nav",
  props: {
    type: String
  },
  methods: {
    onMenuClick() {
      if (this.type === 'mobile-menu') {
        eventBus.$emit('clickOnMenu');
      }
    }
  },
  data() {
    return {
      navigation: [
        {
          name: 'Home',
          text: 'Home'
        },
        {
          name: 'About',
          text: 'About'
        },
        {
          name: 'Key-clients',
          text: 'Key clients'
        },
        {
          name: 'Services',
          text: 'Services'
        },
        {
          name: 'Success-stories',
          text: 'Success stories'
        },
        {
          name: 'Careers',
          text: 'Careers'
        },
        {
          name: 'Contacts',
          text: 'Contacts'
        }
      ],
    }
  }
}
</script>

<style lang="scss">

.main-menu {
  display: flex;
  justify-content: flex-end;
  flex-grow: 2;
  &--hidden-tablet {
    @media (max-width: $viewport-tablet-small) {
      display: none;
    }
  }
}

.main-menu__item-link {
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  color: #818181;
  text-transform: uppercase;
  transition: color .15s ease-in-out;

  &:hover {
    color: #fff;
  }

  &--current {
    color: #fff;
    cursor: default;
  }
}

.main-menu__item-text {
  display: block;
  padding: 10px 22px;

  @media (max-width: $viewport-tablet) {
    padding: 10px;
  }
}

</style>
