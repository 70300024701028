import axios from 'axios'

const instance = axios.create({
    headers: {
        "Content-Type": "application/json",
    },
    cache: "default"
});

instance.interceptors.response.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    });

export default instance;
export const getVacancies = () => instance.get('https://www.rademade.com/api/vacancies');
export const getDepartments = () => instance.get('https://www.rademade.com/api/departments');
export const getSingleVacancy = (slug) => instance.get('https://www.rademade.com/api/vacancies/' + slug);
export const getSuccessStories = () => instance.get('https://www.rademade.com/api/projects');
export const getSystemTypes = () => instance.get('https://www.rademade.com/api/system_types');
export const createRequest = (data) => instance.post('https://www.rademade.com/api/requests', data);
//export const createRequest = (data) => instance.post('http://www.rademade.com/api/requests', data);
export const updateRequest = (id, data) => instance.put('https://www.rademade.com/api/requests/' + id, data);
// export const updateRequest = (id, data) => instance.put('http://www.rademade.com/api/requests/' + id, data);


