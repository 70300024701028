import { render, staticRenderFns } from "./Expertise-block.vue?vue&type=template&id=01718030&scoped=true&"
import script from "./Expertise-block.vue?vue&type=script&lang=js&"
export * from "./Expertise-block.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01718030",
  null
  
)

export default component.exports