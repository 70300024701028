<template>
  <div class="main-info">
    <router-link v-if="data.backLink" :to="{ name: data.backLink }" class="main-info__back-link">{{ data.backLink }}</router-link>
    <h1 class="main-info-title" v-if="data.title" v-html="data.title"></h1>
    <div class="main-info-content-box">
      <div class="main-info-content-box__item">
        <p class="main-info-description" v-if="data.subTitle" v-html="data.subTitle"></p>
      </div>
      <div class="main-info-content-box__item" v-if="data.list">
        <p class="main-info-description">{{ data.listTitle }}</p>
        <ul class="main-info-description-list">
          <li class="main-info-description-list__item" v-for="item in data.list" :key="item">{{ item }}</li>
        </ul>
      </div>
    </div>

    <div class="main-info-actions" v-if="data.link">
     <button class="action-link main-info-actions__link" type="button" :title="data.link.text" @click.stop="openGetQuoteModal">{{ data.link.text }}</button>
      <span class="main-info-actions__text" v-if="data.link && data.link.description">{{ data.link.description }}</span>
    </div>

    <slot></slot>
  </div>
</template>

<script>

import {eventBus} from "@/main";

export default {
  name: "Main-info",
  props: {
    data: Object
  },
  methods: {
    openGetQuoteModal(){
      eventBus.$emit('open-get-quote-modal');
      this.pushGa();
    },
    pushGa() {
      this.$ga.event({
        eventCategory: 'button',
        eventAction: 'click',
        eventLabel: 'quote'
      })
    }
  }
}
</script>

<style lang="scss">
.main-info {
  position: relative;
  padding-bottom: 115px;
  padding-right: 30px;
  padding-top: 70px;
  color: #fff;
  background: url("~@/assets/images/globe.png") no-repeat right 70px;
  background-size: 358px;

  @media (max-width: $viewport-tablet) {
    padding-bottom: 80px;
    padding-right: 0;
  }

  @media (max-width: $viewport-mobile) {
    padding-bottom: 50px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 65px;
    right: -320px;
    width: 360px;
    height: 360px;
    box-shadow: -5px 0 0 5px var(--primary-red-color);
    border-radius: 50%;

    @media (max-width: $viewport-tablet) {
      right: -340px;
    }
  }

  &--no-visual {
    background: none;

    &:after {
      content: none;
    }
  }
}

.main-info-title {
  padding-top: 15px;
  margin-bottom: 75px;
  font-family: Visuelt Pro, sans-serif;
  font-weight: 500;
  font-size: 3.25rem;
  line-height: 1.433962264;
  color: #fff;

  @media (max-width: $viewport-mobile) {
    padding-top: 0;
    font-size: 2.375rem;
    margin-bottom: 50px;
  }
}

.main-info-title__row {
  display: block;
}

.main-info-title__highlighted,
.main-info-title__row--highlighted {
  color: #000;
  -webkit-text-stroke: 1px #fff;
}

.main-info-content-box {
  display: flex;

  @media (max-width: $viewport-tablet-small) {
    flex-wrap: wrap;
  }
}

.main-info-content-box__item {
  padding-right: 90px;

  @media (max-width: $viewport-tablet-small) {
    padding-right: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.main-info-description-list {
  margin-top: 30px;
  font-size: 0.8125rem;
}

.main-info-description-list__item {
  position: relative;
  padding-left: 15px;
  margin-bottom: 20px;
  line-height: 1.42;

  &:last-child {
    margin-bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    width: 3px;
    height: 3px;
    background: #fff;
  }
}

.main-info-description {
  max-width: 750px;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3125rem;
  line-height: 1.80952381;
  color: #fff;

  @media (max-width: $viewport-mobile) {
    font-size: 1.125rem;
  }
}

.main-info-actions {
  display: flex;
  margin-top: 140px;
  align-items: center;

  @media (max-width: $viewport-tablet) {
    margin-top: 80px;
  }

  @media (max-width: $viewport-mobile) {
    margin-top: 60px;
    flex-wrap: wrap;
  }
}

.main-info-actions__link {
  margin-right: 30px;
}

.main-info-actions__text {
  color: #888;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;

  @media (max-width: $viewport-mobile) {
    width: 100%;
    margin-top: 20px;
    font-size: .75rem;
  }
}

.main-info-actions__btn {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 38px;
}

.main-info__back-link {
  position: relative;
  display: inline-block;
  padding-left: 55px;
  margin-bottom: 15px;
  line-height: 38px;
  color: #fff;
  font-weight: bold;
  font-size: 12px;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 12px;
    width: 12px;
    height: 12px;
    background-image: url("data:image/svg+xml,%3Csvg width='13' height='10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.016 4.926a.73.73 0 00-.228-.527L7.618.229C7.451.069 7.28 0 7.102 0c-.406 0-.698.286-.698.673 0 .203.082.374.21.501l1.427 1.448 1.841 1.682-1.472-.09H.717c-.425 0-.717.293-.717.712 0 .412.292.704.717.704h7.694l1.472-.088-1.84 1.682L6.613 8.67a.699.699 0 00-.21.501c0 .388.293.673.699.673.178 0 .35-.07.501-.216l4.184-4.183a.728.728 0 00.228-.52z' fill='%23000'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    transform: translateY(-50%) rotate(180deg);
    filter: invert(100%);
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 38px;
    height: 38px;
    background-color: #161616;
    border-radius: 50%;
  }
}

</style>
