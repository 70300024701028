<template>
  <v-modal @close-modal="onClose" :modal-open="modalOpen">
    <div class="get-quote-step">
      <v-title :title="getSteps.title" :sub-title="getSteps.subTitle"/>
      <div class="get-quote-step__content" :class="{'get-quote-step__content--final' : currentStep === 4}">
        <pulse-loader key="loader" v-if="showLoader" :color="loaderOptions.color" :size="loaderOptions.size"></pulse-loader>
        <keep-alive>
          <component :is="'v-step-' + currentStep" v-if="!showLoader" :data="getStepsList" @step-result="setStepResult"></component>
        </keep-alive>
      </div>
      <v-control
          v-if="currentStep < totalSteps"
          :total-steps="totalSteps"
          :current-step="currentStep"
          :next-active="nextStep && !showLoader"
          @go-to-next-step="goToNextStep"
          @go-to-previous-step="goToPreviousStep">
      </v-control>
    </div>
  </v-modal>
</template>

<script>
//Components
import Modal from '@/components/Modal/Modal'
import Control from '@/components/Modal/Get-quote-step/Controls'
import Title from '@/components/Modal/Get-quote-step/Title'
//Helpers
import * as api from "@/helpers/Api";
import Helper from "@/helpers/Helper";
//Plugins
import { eventBus } from '@/main'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

export default {
  name: "Get-quote-step",
  props: {
    stepOutside: Object,
    modalOpen: Boolean
  },
  computed: {
    getSteps() {
      return this.steps[this.currentStep]
    },
    getStepsList() {
      return this.getSteps.list || []
    },
    firstStepCompleted() {
      return this.currentStep === 1 && this.quoteStepResult && this.quoteStepResult.project_type
    },
    secondStepCompleted() {
      return this.currentStep === 2 &&
          this.quoteStepResult &&
          this.quoteStepResult.project_services &&
          this.quoteStepResult.project_services.length
    },
    nextStep() {
      if (this.firstStepCompleted) {
        return true
      }
      if (this.secondStepCompleted) {
        return true
      }
      if (this.currentStep === 3 || this.currentStep === 4) {
        return true
      }
      return false
    },
    isFormCompleted() {
      return this.quoteStepResult && this.quoteStepResult.name && this.quoteStepResult.email && this.quoteStepResult.termsOfUseAccepted
    }
  },
  data() {
    return {
      currentStep: 1,
      totalSteps: 4,
      steps: {
        1: {
          title: 'What request do you have?',
          subTitle: '',
          list: [
            {
              title: 'Adjust existing software',
              id: 'existing',
              imgName: 'actions-adjust',
              description: 'Reduce bottlenecks, minimize quality issues, and increase scalability of a software.',
              linkText: 'Discuss cooperation'
            },
            {
              title: 'Build a new software',
              id: 'scratch',
              imgName: 'actions-build',
              description: 'Get the project’s feasibility report, an MVP, and the final tested product.',
              linkText: 'Estimate a project'
            },
            {
              title: 'Launch R&D in Ukraine',
              id: 'other',
              imgName: 'planet',
              description: 'Start working with emerging technologies to find disruptive solutions.',
              linkText: 'Discuss cooperation'
            }
          ]
        },
        2: {
          title: 'What kind of help do you need? ',
          subTitle: 'Choose multiple',
          list: [
            {
              id: 'ui_ux_design',
              title: 'UI/UX Design',
              imgName: 'ux'
            },
            {
              id: 'design_requirements',
              title: 'Design the requirements',
              imgName: 'req'
            },
            {
              id: 'web_development',
              title: 'Web development',
              imgName: 'web'
            },
            {
              id: 'expert_consulting',
              title: 'Expert consulting',
              imgName: 'expert'
            },
            {
              id: 'mobile_development',
              title: 'Mobile development',
              imgName: 'mobile'
            },
            {
              id: 'other',
              title: 'Other services',
              imgName: 'other'
            }
          ]
        },
        3: {
          title: 'How should we contact you?',
          subTitle: 'Leave us a hint on how to reach you:',
          list: [
            {
              title: '',
              formGroup: [
                {
                  name: 'name',
                  placeholder: 'Your full name',
                  errorMessage: {
                    empty: 'Please fill out this field',
                    error: ''
                  },
                  required: true
                }
              ]
            },
            {
              title: 'Leave us a hint on how to reach you.',
              formGroup: [
                {
                  name: 'email',
                  placeholder: 'Email',
                  errorMessage: {
                    empty: 'Please fill out this field',
                    error: 'Wrong format of Email, please enter valid Email'
                  },
                  required: true
                },
                {
                  name: 'phone',
                  placeholder: 'Phone',
                  errorMessage: 'Wrong format of phone number, please enter valid phone number',
                  required: false
                },
                {
                  name: 'skype',
                  placeholder: 'Skype',
                  errorMessage: 'Wrong format of phone number, please enter valid phone number',
                  required: false
                },
                {
                  name: 'socialLink',
                  placeholder: 'LinkedIn /FB link',
                  errorMessage: 'Wrong format of phone number, please enter valid phone number',
                  required: false
                }
              ]
            }
          ],
        },
        4: {
          title: 'Thank you!',
          subTitle: ''
        }
      },
      quoteStepResult: {},
      loaderOptions: {
        size: '15px',
        color: '#FF3200'
      },
      showLoader: false
    }
  },
  components: {
    'v-modal': Modal,
    'v-control': Control,
    'v-title': Title,
    'v-step-1': () => import(/* webpackChunkName: "steps" */ /* webpackMode: "lazy" */ `@/components/Modal/Get-quote-step/steps/Step-1`),
    'v-step-2': () => import(/* webpackChunkName: "steps" */ /* webpackMode: "lazy" */ `@/components/Modal/Get-quote-step/steps/Step-2`),
    'v-step-3': () => import(/* webpackChunkName: "steps" */ /* webpackMode: "lazy" */ `@/components/Modal/Get-quote-step/steps/Step-3`),
    //'v-step-4': () => import(/* webpackChunkName: "steps" */ /* webpackMode: "lazy" */ `@/components/Modal/Get-quote-step/steps/Step-4`),
    'v-step-4': () => import(/* webpackChunkName: "steps" */ /* webpackMode: "lazy" */ `@/components/Modal/Get-quote-step/steps/Step-5`),
    PulseLoader
  },
  methods: {
    setStepResult(payload) {
      //payload = {key, value}
      if (payload.key && payload.value) {
        this.quoteStepResult = {
          ...this.quoteStepResult,
          [payload.key]: payload.value,
        }
      } else if (Helper.objectNotEmpty(payload)) {
        //if payload from services page (go to 3d step)
        this.quoteStepResult = payload
      }
    },
    goToNextStep() {
      if (this.firstStepCompleted) {
        this.firstStep();
      }
      if (this.secondStepCompleted) {
        this.secondStep();
      }
      if (this.currentStep === 3 && this.isFormCompleted) {
        this.thirdStep();
      }
      // if (this.currentStep === 4 && this.isFormCompleted) {
      //   this.fourthStep();
      // }
      else {
        eventBus.$emit('validateForm');
      }
    },
    goToPreviousStep() {
      this.currentStep--
    },

    firstStep() {
      this.createRequest(this.quoteStepResult)
      .then((response) => {
        if(response) {
          this.quoteStepResult.id = response.id
          this.currentStep++;
          this.goToNextStep();
        }
      })
      .catch((error) => console.log(error))
    },
    secondStep() {
      this.updateRequest(this.quoteStepResult.id, this.quoteStepResult).then(() => this.currentStep++)
    },
    thirdStep() {
      if (Helper.objectNotEmpty(this.quoteStepResult.files_attributes)) {
        const formData = new FormData();
        this.quoteStepResult.files_attributes.forEach(item => {
          formData.append('request[files_attributes][][file]', item.file)
        })
        formData.append('request[message]', this.quoteStepResult.message)
        this.sendFormRequest(this.quoteStepResult.id, formData)
          .then(() => this.currentStep++)
          .catch((error) => console.log(error))
      } else {
        this.updateRequest(this.quoteStepResult.id, this.quoteStepResult)
          .then(() => this.currentStep++)
          .catch((error) => console.log(error))
      }
      this.fourthStep();
    },
    fourthStep() {
      this.updateRequest(this.quoteStepResult.id, this.quoteStepResult).then(() => {
        this.currentStep++
        this.pushGa(
            'Send a request',
            'Send',
            'quote'
        )
      })
    },
    async createRequest(request) {
      this.showLoader = true;
      return await api.createRequest({ request: request })
        .then((response) => {
          this.showLoader = false;
          return response.data
        })
        .catch((error) => console.log(error))
    },
    async updateRequest(id, request) {
      this.showLoader = true;
      return await api.updateRequest(id,{ request: request })
        .then((response) => {
            this.showLoader = false;
            return response.data
          })
        .catch((error) => console.log(error))
    },
    async sendFormRequest(id, request) {
      this.showLoader = true;
      return await api.updateRequest(id, request)
      .then((response) => {
          this.showLoader = false;
          return response.data
        })
      .catch((error) => console.log(error))
    },
    onClose() {
      this.cleanSteps();
      this.$emit('close-modal');
    },
    cleanSteps() {
      this.currentStep = 1;
      this.quoteStepResult = {};
    },
    pushGa(category, action, label) {
      this.$ga.event({
        eventCategory: category,
        eventAction: action,
        eventLabel: label
      })
    }
  },
  mounted() {
    if (this.stepOutside) {
      this.setStepResult(this.stepOutside);
      this.goToNextStep();
    }
  },
  watch: {
    currentStep: function (value){
      this.quoteStepResult.count_of_steps_taken = value
    }
  },
  beforeDestroy() {
    this.cleanSteps();
  }
}
</script>

<style lang="scss">

.get-quote-step {
  @media (max-width: $viewport-mobile) {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: calc(100vh - 30px);
  }
}

.get-quote-step__content {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  padding: 5px 0;
  min-height: 400px;
  border-bottom: 1px solid #d9d9d9;
  box-sizing: border-box;

  @media (max-width: $viewport-mobile) {
    flex: 1;
  }

  &--final {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }
}
</style>
