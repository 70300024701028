<template>
  <footer class="section-box section-box--black section-box--footer" :class="{'section-box--footer-first': footerMainInfo}">
    <!--  class=footerMainInfo ? "section-box--footer-first" : ""  -->
    <div class="wrapper">
      <p class="footer-title" v-if="!footerWithoutHeader">{{ title }}</p>
      <v-footer-actions :data="footerActions" v-if="!footerWithoutActions"></v-footer-actions>
      <div class="footer-menu">
        <v-dynamic-icon :icon="'logo'" class="footer-menu__logo" viewBox="0 0 183 76"></v-dynamic-icon>
        <v-navigation class="main-menu--hidden-tablet"></v-navigation>
      </div>
      <v-footer-contact-data :data="footerContactData"></v-footer-contact-data>
      <v-footer-underwrite></v-footer-underwrite>
    </div>
  </footer>
</template>

<script>
//Components
import FooterActions from "@/components/Footer/Footer-actions"
import Navigation from "@/components/Navigation/Navigation";
import FooterContactData from "@/components/Footer/Footer-contact-data"
import FooterUnderwrite from "@/components/Footer/Footer-underwrite"

export default {
  name: "Footer",
  props: {
    footerWithoutHeader: Boolean,
    footerMainInfo: Boolean,
    footerWithoutActions: Boolean
  },
  data() {
    return {
      title: 'Ready to bring innovative ideas to life',
      footerActions: {
        title: 'What would you like us to help you with?',
        list: [
          {
            title: 'Adjust existing software',
            icon: 'actions-adjust',
            text: 'Reduce bottlenecks, minimize quality issues, and increase scalability of a software.'
          },
          {
            title: 'Build a new software',
            icon: 'actions-build',
            text: 'Get the project’s feasibility report, an MVP, and the final tested product.'
          },
          {
            title: 'Launch R&D in Ukraine',
            icon: 'planet',
            text: 'Start working with emerging technologies to find disruptive solutions.'
          }
        ]
      },
      footerContactData: {
        social: [
          {
            link: 'https://www.facebook.com/iternalgroup',
            icon: 'fb'
          },
          {
            link: 'https://www.linkedin.com/company/66889177',
            icon: 'in'
          },
          {
            link: 'https://www.instagram.com/iternal_group/',
            icon: 'ig'
          }
        ],
        contacts: [
          {
            title: 'Europe',
            icon: 'eu',
            phone: '',
            email: 'eu@iternal.group'
          },
          {
            title: 'USA',
            icon: 'usa',
            phone: '',
            email: 'usa@iternal.group'
          },
          {
            title: 'Ukraine',
            icon: 'ua',
            phone: '',
            email: 'welcome@iternal.group'
          },
          {
            title: 'HR department',
            icon: '',
            phone: '',
            email: 'hr@iternal.group'
          }
        ]
      }
    }
  },
  components: {
    'v-footer-actions': FooterActions,
    'v-navigation': Navigation,
    'v-footer-contact-data': FooterContactData,
    'v-footer-underwrite': FooterUnderwrite
  }
}
</script>

<style lang="scss">
.footer-title {
  max-width: 950px;
  margin-bottom: 80px;
  font-family: Visuelt Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 5rem;
  line-height: 1.4125;
  color: #fff;

  @media (max-width: $viewport-tablet) {
    margin-bottom: 50px;
    font-size: 4rem;
  }

  @media (max-width: $viewport-mobile) {
    font-size: 3rem;
  }
}

.footer-title__highlighted {
  color: #000;
  -webkit-text-stroke: 1px #FFFFFF;
}

.footer-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  @media (max-width: $viewport-tablet-small) {
    margin-bottom: 30px;
  }

  @media (max-width: $viewport-mobile) {
    margin-bottom: 20px;
  }

  @media (max-width: 380px) {
    justify-content: center;
  }
}

.footer-menu__logo {
  width: 183px;
  height: 76px;
  fill: #fff;

  @media (max-width: $viewport-tablet-small) {
    width: 140px;
  }
}
</style>
