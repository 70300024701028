<template>
  <div>
    <v-slider
        :ref-name="'reviewsSlider'"
        :slides="slides"
        :slider-class="'reviews-slider'"
        :slide-class="'reviews-slide'"
        class="reviews-slider-container">
      <template v-slot:slide="slideContent">
        <div class="reviews-slide__box">
          <img
              :src="require('@/assets/images/reviews/' + slideContent.slide.image)"
              v-if="slideContent.slide.image"
              :alt="slideContent.slide.name"
               class="reviews-slide__img">
          <div class="reviews-slide__simple-text" v-html="slideContent.slide.content"></div>
          <div class="reviews-slide-underwrite">
            <span class="reviews-slide-underwrite__title" v-if="slideContent.slide.name">{{ slideContent.slide.name }}</span>
            <span class="reviews-slide-underwrite__position" v-if="slideContent.slide.position">{{ slideContent.slide.position }}</span>
          </div>
        </div>
      </template>
    </v-slider>
  </div>
</template>

<script>
import Slider from '@/components/Slider/Slider'

export default {
  name: 'Review-slider',
  data() {
    return {
      slides: [
        {
          image: 'review-signd.jpg',
          content: '<p>We came to ITernal as another company has let us down. And I\'ll be honest, we\'ve been difficult clients to them with requesting new features during sprints etc. Nevertheless they have been patient with us and delivered a product (front- and back-end) that worked from day one and still working and generating a good revenue although not being touched for 4 months now. It\'s stable, does exactly what we requested and has proven to take the load we expected.</p><p>The costs for the development were reasonable and having in mind what we have now I would call myself a happy client.</p>',
          name: 'Andreas Jarzabek',
          position: 'Head of Process and Organizational Change Management at SignD'
        },
        {
          image: 'review-kevin.png',
          content: '<p>The platform fostered dynamic interactions between users and brands, which contributed to increased social media activity and sales growth. Besides their meticulousness, timeliness, and expansive technical knowledge, ITernal maintained consistent communication to complete the project efficiently.</p>',
          name: 'Kevin Markosvki',
          position: 'Co-Founder & CEO at Spitche'
        },
        {
          image: 'review-tristan.jpg',
          content: '<p>ITernal are a very trustworthy and honest outfit, who have always demonstrated their reliability and commitment to their clients &amp; partners.</p><p>I look forward to an ongoing business relationship with the ITernal and recommend them unconditionally</p>',
          name: 'Tristan Senycia',
          position: 'Founder at London Investable'
        },
        {
          image: 'review-youteam.jpg',
          content: '<p>We’ve cooperated with ITernal on both web and mobile development projects. They provide a great service to us and our partners, you feel that the guys care about your business.</p>\n' +
              '<p>ITernal brings only professional teams into projects, and they create successful products. The teams know how to improve products in order to bring value to the target audience in the long term.</p>',
          name: 'Bohdan Flyorkiv',
          position: 'Head of Customer Success at YouTeam'
        },
        {
          image: 'review-visualizers.png',
          content: '<p>VISUALIZERS have been successfully and fruitfully working with ITernal since the end of 2014. The company has proved itself as a reliable partner, that can provide:</p>\n' +
              '<ul>\n' +
              '<li>a flexible approach to customer requirements;</li>\n' +
              '<li>a high level of expertise in website development;</li>\n' +
              '<li>qualitative client service.</li>\n' +
              '</ul>\n' +
              '<p>The accuracy of task realization should be mentioned; along with the use of latest technologies, the highest quality of the development process, compliance with deadlines and highest level of client service, and a rapid response to client requests.</p>',
          name: 'M.Avramenko',
          position: 'Director, VISUALIZERS Pvt Co., Ukraine'
        },
        {
          image: 'review-facewatch.png',
          content: '<p>We recently chose ITernal to design and create iOS and Android screens for a new Mobile app we are currently releasing to mobile app stores.</p>\n' +
              '<p>The ITernal Team was very responsive to all our requests and made amendments very promptly and to specification.</p>\n' +
              '<p>This team comes highly recommended by us, as a team you can rely on to get the results you need.</p>',
          name: 'Emma Hyne',
          position: 'Facewatch Limited, UK'
        },
      ]
    }
  },
  components: {
    'v-slider': Slider
  }

}
</script>
<style lang="scss">
.swiper-container.reviews-slider {
  align-items: stretch;
  height: 100%;
  max-width: 820px;
  margin-left: 0;
  overflow: visible;

  @media (max-width: $viewport-tablet) {
    max-width: 550px;
    margin-left: 0;
  }
  @media (max-width: $viewport-mobile) {
    max-width: 280px;
  }
}

.swiper-slide.reviews-slide {
  height: auto;
  outline: none;
}

.reviews-slide__box {
  position: relative;
  display: block;
  height: 100%;
  padding: 70px 90px 90px;
  background-color: #fff;
  box-sizing: border-box;

  @media (max-width: $viewport-tablet) {
    padding: 40px 45px;
  }

  @media (max-width: $viewport-mobile) {
    padding: 30px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 70px;
    right: 90px;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M32 24V12h-8c0-4.41 3.586-8 8-8V0c-6.617 0-12 5.383-12 12v12h12zM12 24V12H4c0-4.41 3.586-8 8-8V0C5.383 0 0 5.383 0 12v12h12z' fill='%23000'/%3E%3C/svg%3E");

    @media (max-width: $viewport-mobile) {
      width: 18px;
      height: 18px;
      top: 30px;
      right: 30px;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 10000px;
    background-color: #F1F1F1;
  }
}

.reviews-slide__img {
  height: 92px;
  margin-bottom: 35px;
  border-radius: 50%;

  @media (max-width: $viewport-mobile) {
    height: 74px;
    margin-bottom: 25px;
  }
}

.reviews-slide__simple-text {
  font-style: normal;
  font-weight: normal;
  font-size: 1.3125rem;
  line-height: 1.80952381;

  @media (max-width: $viewport-tablet) {
    font-size: 1.125rem;
  }

  @media (max-width: $viewport-mobile) {
    font-size: .875rem;
  }

  p {
    margin-bottom: 40px;

    @media (max-width: $viewport-tablet) {
      margin-bottom: 25px;
    }

    @media (max-width: $viewport-mobile) {
      margin-bottom: 20px;
    }
  }

  ul {
    margin-bottom: 40px;
    font-size: 0.875rem;
    line-height: 1.80952381;

    @media (max-width: $viewport-tablet) {
      margin-bottom: 25px;
    }

    @media (max-width: $viewport-mobile) {
      font-size: 0.75rem;
      margin-bottom: 20px;
    }

    li {
      position: relative;
      padding-left: 25px;
      margin-bottom: 10px;

      @media (max-width: $viewport-tablet) {
        margin-bottom: 5px;
      }

      @media (max-width: $viewport-mobile) {
        padding-left: 20px;
      }

      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 11px;
        height: 11px;
        background: transparent;
        border: 1px solid #000;
        box-sizing: border-box;
        border-radius: 50%;

        @media (max-width: $viewport-mobile) {
          width: 9px;
          height: 9px;
          top: 7px;
        }
      }
    }
  }
}

.reviews-slide-underwrite {
  position: relative;
  padding-top: 45px;

  @media (max-width: $viewport-tablet) {
    padding-top: 30px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    width: 90px;
    background: #000;
  }
}

.reviews-slide-underwrite__title {
  display: block;
  font-family: Visuelt Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 2.125rem;
  line-height: 1.117647059;

  @media (max-width: $viewport-tablet) {
    font-size: 1.875rem;
  }

  @media (max-width: $viewport-mobile) {
    font-size: 1.5rem;
  }
}

.reviews-slide-underwrite__position {
  display: block;
  margin-top: 15px;
  font-size: 0.875rem;
  line-height: 1.42;

  @media (max-width: $viewport-tablet) {
    margin-top: 10px;
  }
}
</style>
