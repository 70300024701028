<template>
  <div>
    <h2 class="section-title" v-if="title">
      <span class="section-title__text">{{ title }}</span>
    </h2>
    <p class="section-description-row" v-if="subTitle">{{ subTitle }}</p>

    <div class="services-slider-container">
      <v-services-slider></v-services-slider>
    </div>
  </div>
</template>

<script>
// Components
import ServicesSlider from '@/components/Slider/Services-slider'

export default {
  name: "Services-block",
  data(){
    return {
      title: 'Our 360° services',
      subTitle: 'Large successful enterprises and booming startups work with us. Since each client is unique and has different needs, we offer a wide range of software development services that unlock the potential of our clients and fuel their growth.',

    }
  },
  components: {
    'v-services-slider': ServicesSlider
  },
}
</script>
