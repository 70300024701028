<template>
  <div class="content" id="app">
    <v-header/>
    <router-view/>
    <v-footer
        :footer-without-header="$route.meta.footerWithoutHeader"
        :footer-main-info="$route.meta.footerMainInfo"
        :footer-without-actions="$route.meta.footerWithoutActions"
    />
    <transition name="fade">
      <v-get-quote-step
          v-if="showGetQuoteModal"
          :stepOutside="stepValue"
          :modal-open='showGetQuoteModal'
          @close-modal="closeGetQuoteModal"/>
    </transition>
    <v-cookies-popup/>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer"
import GetQuoteStep from '@/components/Modal/Get-quote-step/Get-quote-step'
import { eventBus } from "@/main";
import CookiesPopup from './components/CookiesPopup/CookiesPopup.vue';

export default {
  name: 'Home',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'ITernal Group – IT vendor for complex software solutions',
    // all titles will be injected into this template
    titleTemplate: '%s'
  },
  data(){
    return {
      showGetQuoteModal: false,
      stepValue: {}
    }
  },
  components: {
    'v-header': Header,
    'v-footer': Footer,
    'v-get-quote-step': GetQuoteStep,
    'v-cookies-popup': CookiesPopup
  },
  methods: {
    closeGetQuoteModal(){
      this.showGetQuoteModal = false
      this.stepValue = {}
      document.body.classList.remove('is-modal-open');
    },
    openGetQuoteModal(){
      this.showGetQuoteModal = true
      document.body.classList.add('is-modal-open');
    },
    setStepValue(value) {
      this.stepValue = value
    }
  },
  mounted() {
    const self = this;
    eventBus.$on('open-get-quote-modal', (value) => {
      self.openGetQuoteModal();
      if (value) {
        self.setStepValue(value);
      } else {
        //Mocked value for going to 3 step
        self.setStepValue({
          project_type: 'other',
          project_services: ['other']
        });
      }
    })
  }

}
</script>
