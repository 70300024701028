<template>
  <div class="footer-underwrite">
    <span class="footer-underwrite__text">{{ `2011 - ${getYear()} © ITernal. All rights reserved` }}</span>
    <ul class="footer-underwrite__link-list">
      <li class="footer-underwrite__item" v-for="item in legal" :key="item.name">
        <router-link
          :to="{ name: item.name }"
          exact
          class="footer-underwrite__item-link"
          active-class="footer-underwrite__item-link--current">
          <span class="footer-underwrite__item-text">{{ item.text }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Footer-underwrite",
  data() {
    return {
      legal: [
        {
          name: 'Privacy-notice',
          text: 'Privacy Notice'
        },
        {
          name: 'Term-of-use',
          text: 'Term of use'
        },
        {
          name: 'Cookies',
          text: 'Cookies'
        }

      ],
    }
  },
  methods: {
    getYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss">
.footer-underwrite {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 40px;

  @media (max-width: $viewport-tablet-small) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  @media (max-width: $viewport-mobile) {
    align-items: center;
  }
}

.footer-underwrite__text {
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 2.166666667;
  color: #FFFFFF;

  @media (max-width: $viewport-tablet-small) {
    margin-top: 20px;
  }
}

.footer-underwrite__link-list {
  display: flex;
  justify-content: flex-end;
  flex-grow: 2;
  &--hidden-tablet {
    @media (max-width: $viewport-tablet-small) {
      display: none;
    }
  }
}

.footer-underwrite__item-link {
  display: inline-block;
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  color: #818181;
  text-transform: uppercase;
  transition: color .15s ease-in-out;

  &:hover {
    color: #fff;
  }

  &--current {
    color: #fff;
    cursor: default;
  }
}

.footer-underwrite__item-text {
  display: block;
  padding: 10px 22px;

  @media (max-width: $viewport-tablet) {
    padding: 10px;
  }
}


</style>
