<template>
  <div class="quote-actions-list">
    <label
        class="quote-actions-list-item-box"
        v-for="item in data"
        :key="item.id">
      <input
          type="radio"
          name="step1"
          class="quote-actions-list-item__input"
          @change="onRadioChecked($event, item)">
      <span class="quote-actions-list-item">
        <span>
          <span class="quote-actions-list-item__title">{{ item.title }}
          <v-dynamic-icon
              class="quote-actions-list-item__title-img"
              :class="`quote-actions-list-item__title-img--${item.imgName}`"
              :icon="item.imgName">
          </v-dynamic-icon>
        </span>
        </span>
        <span class="quote-actions-list-item__description">{{ item.description }}</span>
        <span class="quote-actions-list-item__link">{{ item.linkText }}</span>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: "Step1",
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      checkedItem: ''
    }
  },
  methods: {
    onRadioChecked(event, item) {
      if (event.target.checked) {
        this.checkedItem = item.id;
        this.$emit('step-result', { key: 'project_type', value: item.id })
      }
    },
  }
}
</script>

<style lang="scss">

.quote-actions-list {
  display: flex;
  align-items: stretch;

  @media (max-width: $viewport-tablet-small) {
    flex-wrap: wrap;
  }
}

.quote-actions-list--inverse {
  color: #fff;

  .quote-actions-list-item__link {
    &::after {
      background-image: url($arrow-icon-inverse);
    }
  }
  .quote-actions-list-item{
    &:hover {
      box-shadow: none;
      background-color: #222;
    }
  }
  .quote-actions-list-item--selected {
    background-color: #222;
  }
  .quote-actions-list-item__input {
    &:checked ~ .quote-actions-list-item {
      background-color: #222;
    }
  }
}

.quote-actions-list-item-box {
  width: calc(100% / 3);
  margin-right: 50px;

  @media (max-width: $viewport-desktop-large) {
    margin-right: 20px;
  }

  @media (max-width: $viewport-tablet) {
    margin-right: 0;
  }

  @media (max-width: $viewport-tablet-small) {
    width: 100%;
  }

  &:last-child {
    margin-right: 0;
  }
}

.quote-actions-list-item {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 50px 50px 40px;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color .2s ease-in-out, box-shadow .14s ease-in-out;

  @media (max-width: $viewport-desktop-large) {
    padding: 30px;
  }

  @media (max-width: $viewport-mobile) {
    padding: 15px;
  }

  &:hover {
    box-shadow: 0 0 0 3px inset #fff;

    .quote-actions-list-item__link {
      opacity: 1;
    }
  }

  &--selected {
    background-color: #fff;

    .quote-actions-list-item__link {
      opacity: 1;
    }
  }
}

.quote-actions-list-item__input {
  position: absolute;
  clip: rect(0, 0, 0, 0);

  &:checked ~ .quote-actions-list-item {
    background-color: #fff;

    .quote-actions-list-item__link {
      opacity: 1;
    }
  }
}

.quote-actions-list-item__title {
  position: relative;
  font-family: Visuelt Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 2.125rem;
  line-height: 1.117647059;

  @media (max-width: $viewport-desktop-large) {
    display: block;
    padding-right: 40px;
  }

  @media (max-width: $viewport-tablet-small) {
    font-size: 1.75rem;
  }
}

.quote-actions-list-item__title-img {
  position: absolute;

  &--actions-adjust {
    width: 36px;
    height: 30px;
    right: -42px;
    bottom: 6px;

    @media (max-width: $viewport-desktop-large) {
      bottom: auto;
      right: auto;
      margin-left: 10px;
    }
    @media (max-width: $viewport-tablet-small) {
      width: 33px;
      height: 27px;
    }
    @media (max-width: $viewport-mobile) {
      right: 0;
      top: 0;
    }
  }

  &--actions-build {
    width: 24px;
    height: 42px;
    right: -34px;
    bottom: 0;
    
    @media (max-width: $viewport-desktop-large) {
      bottom: auto;
      right: auto;
      margin-left: 10px;
    }

    @media (max-width: $viewport-tablet-small) {
      width: 19px;
      height: 33px;
    }

    @media (max-width: $viewport-mobile) {
      right: 0;
      top: 0;
    }
  }

  &--planet {
    width: 28px;
    height: 28px;
    right: -34px;
    bottom: 7px;

    @media (max-width: $viewport-desktop-large) {
      bottom: auto;
      right: auto;
      margin-left: 10px;
    }

    @media (max-width: $viewport-tablet-small) {
      width: 24px;
      height: 24px;
    }
    @media (max-width: $viewport-mobile) {
      right: 0;
      top: 0;
    }
  }
}

.quote-actions-list-item__description {
  display: block;
  margin: 50px 0 40px;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 2.166666667;

  @media (max-width: $viewport-desktop-large) {
      margin: 25px 0;
  }

  @media (max-width: $viewport-mobile) {
    margin: 15px 0;
  }
}

.quote-actions-list-item__link {
  display: inline-block;
  position: relative;
  padding: 10px 65px 10px 0;
  margin-top: auto;
  font-style: normal;
  font-weight: bold;
  font-size: .75rem;
  opacity: 0;
  transition: opacity .2s ease-in-out;

  @media (max-width: $viewport-tablet-small) {
    opacity: 1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 13px;
    height: 10px;
    background-image: url($arrowIcon);
    background-repeat: no-repeat;
    transform: translateY(-50%);

    @media (max-width: $viewport-tablet-small) {
      position: static;
      display: inline-block;
      margin-left: 15px;
      transform: none;
    }
  }
}
</style>
