<template>
  <div>
    <h2 class="section-title" v-if="data.title">
      <span class="section-title__text">{{ data.title }}</span>
    </h2>
    <p class="section-description-row" v-if="data.subTitle">{{ data.subTitle }}</p>

    <h3 class="section-subbox-title section-subbox-title--top-indent" v-if="data.infoCelledTitle" >{{ data.infoCelledTitle }}</h3>
    <v-info-celled v-if="data.infoCelled && data.infoCelled.length" :data="data.infoCelled"></v-info-celled>

    <h3 class="section-subbox-title section-subbox-title--top-indent" v-if="data.marketExpertiseTitle">{{ data.marketExpertiseTitle }}</h3>
    <v-market-expertise v-if="data.marketExpertise && data.marketExpertise.length" :data="data.marketExpertise"></v-market-expertise>
  </div>
</template>

<script>
//Components
import InfoCelled from '@/components/layout/Expertise/Info-celled'
import MarketExpertize from '@/components/layout/Expertise/Market-expertise'

export default {
  name: "Expertise-block",
  props: {
    data: Object
  },
  components: {
    'v-info-celled': InfoCelled,
    'v-market-expertise': MarketExpertize
  }
}
</script>

<style scoped>

</style>
