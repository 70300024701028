
<template>
  <div v-if="!cookie" class="cookies-box">
    <div class="wrapper">
      <div class="cookies-content">
        <span class="cookies-text">We use cookies to ensure that we give you the best experience on our website. If you continue without changing your settings, we'll assume that you are happy to receive all cookies.
        </span>
        <div class="cookies-actions"><a href="/cookies" class="cookies-link">Read more</a>
          <button class="action-link cookies-btn" type="button" v-on:click="acceptCookiesPolicy">Agree</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CookiesPopup',
  data() {
    return {
      cookie: ''
    }
  },
  mounted() {
    if (localStorage.cookie) {
      this.cookie = localStorage.cookie;
    }
  },
  methods: {
    acceptCookiesPolicy() {
      localStorage.cookie = 'cookie';
      this.cookie = 'cookie';
    }
  }
}

</script>

<style lang="scss">
  .cookies-box {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 16000002;
    box-shadow: 0 4px 25px 0 rgba(46,40,15,.3);
  }

.cookies-content {
  display: flex;
  width: 100%;
  padding: 15px 0;
  box-sizing: border-box;

  @media (max-width: $viewport-tablet) {
    flex-wrap: wrap;
  }
}


.cookies-text {
  display: block;
  width: 80%;
  padding-right: 30px;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  box-sizing: border-box;

  @media (max-width: $viewport-tablet) {
    width: 100%;
    margin-bottom: 10px;
    padding-right: 0;
    font-size: 12px;
    line-height: 18px;
  }
}

.cookies-actions {
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: $viewport-tablet) {
    width: 100%;
  }
}

.cookies-link {
  display: inline-block;
  vertical-align: middle;
  margin-right: 30px;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  border-bottom: 1px solid #333333;
  transition: border-bottom-color .3s ease;
  cursor: pointer;

  @media (max-width: $viewport-tablet) {
    font-size: 14px;
    line-height: 18px;
  }

  &:hover {
    border-bottom-color: transparent;
  }
}


.cookies-btn {
  min-width: 115px;
}

</style>
