<template>
    <div class="slider-controls">
        <button class="slider-nav-prev slider-controls__prev" @click="prev">
            <v-dynamic-icon :icon="'slider-arrow-left'"></v-dynamic-icon>
        </button>
        <span class="slider-controls__current-slide">{{ currentSlideIndex }}</span>
        <span class="slider-controls__total-slides">{{ `/${totalSlides}` }}</span>
        <button class="slider-nav-next slider-controls__next" @click="next">
          <v-dynamic-icon :icon="'slider-arrow-right'"></v-dynamic-icon>
        </button>
      </div>
</template>
<script>
export default {
    props: {
        currentSlideIndex: {
            type: [ String, Number ]
        },
        totalSlides: {
            type: [ String, Number ]
        }
    },
    methods: {
        prev() {
            this.$emit('slide-prev')
        }, 
        next() {
            this.$emit('slide-next')
        }
    }
}
</script>
<style lang="scss">

</style>