<template>
  <div>
    <v-slider
        :ref-name="'servicesSlider'"
        :slides="slides"
        :slider-class="'services-slider'"
        :slide-class="'services-slide'"
        :options="options"
        class="services-slider-container">
      <template v-slot:slide="slideContent">
        <div class="services-slide__box">
          <v-dynamic-icon
              :icon="slideContent.slide.icon"
              v-if='slideContent.slide.icon'
              width="50"
              height="50"
              class="services-slide__img">
          </v-dynamic-icon>
          <span class="services-slide__title">{{ slideContent.slide.title }}</span>
          <span class="services-slide__description">{{ slideContent.slide.text }}</span>
        </div>
      </template>
    </v-slider>
  </div>
</template>

<script>
import Slider from '@/components/Slider/Slider'

export default {
  name: "Services-slider",
  data(){
    return {
      options: {
        initialSlide: 0,
        slidesPerView: 1,
        spaceBetween: 0,
        setWrapperSize: true,
        loop: false,
        loopAdditionalSlides: 3,
      },
      slides: [
        {
          icon: 'services-product',
          title: 'Product development',
          text: 'Bring your idea and let us analyze, build, design, and release a top-notch custom software to market.'
        },
        {
          icon: 'services-managed',
          title: 'Managed service',
          text: 'Focus on your business and save a lot of resources with a whole range of recurring IT services.'
        },
        {
          icon: 'services-dedicated',
          title: 'Dedicated team',
          text: 'Expand your development capabilities and tap into new tech expertise with a team working just for you.'
        }
      ]
    }
  },
  components: {
    'v-slider': Slider
  }
}
</script>

<style lang="scss">
.services-slider-container {
  margin-top: 100px;
  position: relative;

  .services-slide {
    height: auto;
    outline: none;

    &:hover {

      .services-slide__box {
        background-color: #fff;
      }

      .services-slide__link {
        opacity: 1;
      }
    }
  }
}

.swiper-container.services-slider {
  overflow: visible;
  margin-left: -45px;
  max-width: 550px;

  @media (max-width: $viewport-tablet) {
    max-width: 400px;
    margin-left: 0;
  }
  @media (max-width: $viewport-mobile) {
    max-width: 280px;
  }
}

.services-slide__box {
  display: block;
  height: 100%;
  padding: 45px 45px 40px;
  box-sizing: border-box;
  transition: background-color .2s ease-in-out;

  @media (max-width: $viewport-tablet) {
    padding: 35px 35px 30px;
  }

  @media (max-width: $viewport-mobile) {
    padding: 30px 30px 20px;
    background: #fff;
    border-left: 1px solid #F1F1F1;
    box-sizing: border-box;
  }
}

.services-slide__img {
  width: 138px;
  height: 138px;
  margin-bottom: 115px;

  @media (max-width: $viewport-tablet) {
    width: 92px;
    height: 92px;
    margin-bottom: 50px;
  }

  @media (max-width: $viewport-mobile) {
    width: 70px;
    height: 70px;
    margin-bottom: 30px;
  }
}

.services-slide__title {
  display: block;
  margin-bottom: 15px;
  font-family: Visuelt Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 2.125rem;
  line-height: 1.117647059;
  color: #000000;

  @media (max-width: $viewport-tablet) {
    font-size: 1.75rem;
  }
}

.services-slide__description {
  display: block;
  margin-bottom: 35px;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 2.714285714;
  color: #000000;

  @media (max-width: $viewport-tablet) {
    line-height: 1.8;
  }

  @media (max-width: $viewport-mobile) {
    margin-bottom: 30px;
  }
}

.services-slide__link {
  display: inline-block;
  padding: 10px 65px 10px 0;
  position: relative;
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  color: #000000;
  opacity: 0;
  transition: opacity .2s ease-in-out;

  @media (max-width: $viewport-mobile) {
    opacity: 1;
  }
}

.slider-controls {
  position: absolute;
  top: -60px;
  right: 15px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  text-align: center;
  text-transform: uppercase;
  color: #9A9A9A;

  @media (max-width: $viewport-tablet) {
    top: -40px;
    right: 0;
  }

  &--with-header {
    top: -115px;

    @media (max-width: $viewport-tablet) {
      top: -95px;
    }

    @media (max-width: $viewport-mobile) {
      top: -75px;
    }
  }
}

.slider-controls__prev,
.slider-controls__next {
  position: relative;
  width: 33px;
  height: 10px;
}

.slider-controls__prev {
  margin-right: 40px;

  @media (max-width: $viewport-tablet) {
    margin-right: 15px;
  }
}

.slider-controls__next {
  margin-left: 40px;

  @media (max-width: $viewport-tablet) {
    margin-left: 15px;
  }

  &:after {
    right: 0;
  }
}

.slider-controls__current-slide {
  color: #000;
}
</style>
