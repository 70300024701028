<template>
  <div class="expertise-slider-container">
    <div class="expertise-slider">
      <div class="expertise-slide" v-for="item in data" :key="item.title">
        <div class="expertise-slide__box">
          <span class="expertise-slide-title expertise-slide__title">{{ item.title }}
            <component :is="'v-icon-' + item.icon" v-if='item.icon'  class="expertise-slide-title__img" :class="'expertise-slide-title__img--' + item.icon"></component>
          </span>
          <span class="expertise-slide__description" v-if="item.text">{{ item.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Icons
import IconExpertiseHealthcare from '@/assets/icons/expertise-healthcare.svg'
import IconExpertiseFintech from '@/assets/icons/expertise-fintech.svg'
import IconExpertiseTrading from '@/assets/icons/expertise-trading.svg'

export default {
  name: "Market-expertise",
  props: {
    data: Array
  },
  components: {
    'v-icon-expertise-healthcare': IconExpertiseHealthcare,
    'v-icon-expertise-fintech': IconExpertiseFintech,
    'v-icon-expertise-trading': IconExpertiseTrading
  }
}
</script>

<style lang="scss">
.expertise-slider-container {
  position: relative;
}

.expertise-slider {
  display: flex;

  @media (max-width: $viewport-tablet) {
    flex-wrap: wrap;
  }
}

.expertise-slide {
  width: 33.333%;

  @media (max-width: $viewport-tablet) {
    width: 50%;
  }
  @media (max-width: $viewport-mobile) {
    width: 100%;
    margin-bottom: 1px;
  }

  &:hover {

    .expertise-slide__box {
      background: #F1F1F1;
    }

    .expertise-slide__link-container {
      opacity: 1;
    }
  }
}

.expertise-slide__box {
  display: block;
  //max-width: 360px;
  padding: 40px 40px 35px;
  height: 100%;
  box-sizing: border-box;
  transition: background-color .2s ease-in-out;

  @media (max-width: $viewport-mobile) {
    margin-bottom: 10px;
    padding: 30px 30px 20px;
    background-color: #F1F1F1;
    //border-right: 1px solid #fff;
    box-sizing: border-box;
  }
}

.expertise-slide-title {
  display: inline;
  position: relative;
  margin-bottom: 15px;
  font-family: Visuelt Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 2.125rem;
  line-height: 1.117647059;
  color: #000000;

  @media (max-width: $viewport-tablet) {
    font-size: 1.75rem;
  }
}

.expertise-slide-title__img {
  position: absolute;

  &--expertise-healthcare {
    top: 0;
    right: -50px;
    width: 40px;
    height: 40px;

    @media (max-width: $viewport-tablet) {
      top: -3px;
      right: -46px;
      width: 35px;
      height: 35px;
    }
  }

  &--expertise-trading {
    top: 3px;
    right: -50px;
    width: 37px;
    height: 35px;

    @media (max-width: $viewport-tablet) {
      top: 1px;
      right: -43px;
      width: 33px;
      height: 32px;
    }
  }

  &--expertise-fintech {
    top: 0;
    right: -50px;
    width: 35px;
    height: 34px;

    @media (max-width: $viewport-tablet) {
      top: 0px;
      right: -43px;
      width: 29px;
      height: 29px;
    }
  }
}

.expertise-slide__title {
}

.expertise-slide__description {
  display: block;
  margin-top: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 2.166666667;
  color: #000000;
}

.expertise-slide__link-container {
  display: block;
  margin-top: 60px;
  opacity: 0;
  transition: opacity .2s ease-in-out;


  @media (max-width: $viewport-tablet) {
    margin-top: 30px;
  }

  @media (max-width: $viewport-mobile) {
    opacity: 1;
    margin-top: 20px;
  }
}

.expertise-slide__cases-amount {
  display: inline-block;
  margin-right: 8px;
  padding: 2px 2px 3px 3px;
  min-width: 22px;
  background: var(--primary-red-color);
  font-style: normal;
  font-weight: bold;
  font-size: .75rem;
  text-align: center;
  color: #FFFFFF;
  border-radius: 22px;
  box-sizing: border-box;
}

.expertise-slide__link {
  display: inline-block;
  padding: 10px 65px 10px 0;
  position: relative;
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  color: #000000;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 13px;
    height: 10px;
    background: url('/sprite.svg#arrow-right');
    transform: translateY(-50%);
  }
}
</style>
