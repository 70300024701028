<template>
  <div>
    <h2 class="section-title" v-if="title">{{ title }}</h2>
    <p class="section-description-row" v-if="subTitle">{{ subTitle }}</p>

    <div class="reviews-slider-container">
      <v-review-slider></v-review-slider>
    </div>
  </div>
</template>

<script>
import ReviewSlider from "@/components/Slider/Review-slider"

export default {
  name: "Reviews-block",
  data() {
    return {
      title: 'What our customers say',
      subTitle: 'Check stories from our customers to learn how ITernal helped them transform their businesses with software solutions, apps, and more.',
    }
  },
  components: {
    'v-review-slider': ReviewSlider
  },
}
</script>

<style lang="scss">
.reviews-slider-container {
  position: relative;
  margin-top: 65px;
}
</style>
