<template>
  <div class="slider-container">
    <v-slider-control
        :current-slide-index="currentSlideIndex"
        :total-slides='totalSlides'
        @slide-next="swiper.slideNext()"
        @slide-prev="swiper.slidePrev()">
    </v-slider-control>

    <swiper :ref="refName" :options="sliderOptions" @slideChange="onSlideChange" :class="sliderClass">
      <swiper-slide v-for="slide in slides" :key="slide.title" :class="slideClass">
        <slot name="slide" :slide="slide"></slot>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import SliderControl from '@/components/Slider/Slider-control'

export default {
  name: 'Slider',
  props: {
    refName: {
      type: String,
      required: true
    },
    slides: {
      type: Array,
      required: true
    },
    sliderClass: {
      type: String
    },
    slideClass: {
      type: String
    },
    options: {
      type: Object
    }
  },
  computed: {
    swiper() {
      return this.$refs[this.refName].$swiper
    },
    sliderOptions() {
      if (this.options) {
        return Object.assign(this.swiperOptions, this.options)
      } else {
        return this.swiperOptions
      }
    }
  },
  data() {
    return {
      swiperOptions: {
        initialSlide: 0,
        slidesPerView: 1,
        spaceBetween: 0,
        loop: false,
        loopAdditionalSlides: 3,
        autoHeight: false,
        grabCursor: true,
        navigation: {
          prevEl: '.slider-nav-prev',
          nextEl: '.slider-nav-next'
        },
      },
      totalSlides: '',
      currentSlideIndex: '',


    }
  },

  components: {
    Swiper,
    SwiperSlide,
    'v-slider-control': SliderControl
  },
  methods: {
    toPadStart(value) {
      return String(value).padStart(2, '0')
    },
    onSlideChange(swiper) {
      this.currentSlideIndex = this.toPadStart(swiper.realIndex + 1)
    }
  },
  mounted() {
    this.totalSlides = this.toPadStart(this.slides.length)
    this.currentSlideIndex = this.toPadStart(this.swiper.realIndex + 1)
  }
}
</script>
