<template>
  <div>
    <p class="footer-actions-title" v-if="data.title"> {{ data.title }}</p>
    <v-step-1 :data="list" @step-result="openGetQuoteModal" class="footer-actions-list quote-actions-list--inverse"></v-step-1>
  </div>
</template>

<script>
//Components
import Step1 from "@/components/Modal/Get-quote-step/steps/Step-1"
import {eventBus} from "@/main";

export default {
  name: "Footer-actions",
  props: {
    data: Object
  },
  data() {
    return {
      list: [
        {
          title: 'Adjust existing software',
          id: 'existing',
          imgName: 'actions-adjust',
          description: 'Reduce bottlenecks, minimize quality issues, and increase scalability of a software.',
          linkText: 'Discuss cooperation'
        },
        {
          title: 'Build a new software',
          id: 'scratch',
          imgName: 'actions-build',
          description: 'Get the project’s feasibility report, an MVP, and the final tested product.',
          linkText: 'Estimate a project'
        },
        {
          title: 'Launch R&D in Ukraine',
          id: 'other',
          imgName: 'planet',
          description: 'Start working with emerging technologies to find disruptive solutions.',
          linkText: 'Discuss cooperation'
        }
      ]
    }
  },
  components: {
    'v-step-1': Step1
  },
  methods: {
    openGetQuoteModal(){
      eventBus.$emit('open-get-quote-modal');
      this.pushGa();
    },
    pushGa() {
      this.$ga.event({
        eventCategory: 'button',
        eventAction: 'click',
        eventLabel: 'quote'
      })
    }
  }
}
</script>

<style lang="scss">
.footer-actions-title {
  margin-bottom: 65px;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.583333333;
  color: #FFFFFF;

  @media (max-width: $viewport-tablet) {
    font-size: 1.25rem;
    margin-bottom: 50px;
  }
}
.footer-actions-list {
  margin-bottom: 115px;

  @media (max-width: $viewport-tablet) {
    margin-bottom: 40px;
  }

  @media (max-width: $viewport-mobile) {
    flex-wrap: wrap;
  }
}
</style>
