<template>
  <div class="info-celled">
    <div class="info-celled-item info-celled__item" v-for="item in data" :key="item.title">
      <p class="info-celled-item__title">{{ item.title }}</p>
      <p class="info-celled-item__description">{{ item.description }}</p>
      <ul class="info-celled-item-list info-celled-item__list" v-if="data.itemList && data.itemList.length">
        <li class="li.info-celled-item-list__item" v-for="list in data.itemList" :key="list.text">{{ list.text }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Info-celled",
  props: {
    data: Array
  }
}
</script>

<style lang="scss">
.info-celled {
  display: flex;

  @media (max-width: $viewport-mobile) {
    flex-wrap: wrap;
  }
}

.info-celled__item {

}

.info-celled-item {
  width: 33.333%;
  padding-right: 90px;

  @media (max-width: $viewport-tablet-small) {
    padding-right: 50px;
  }

  @media (max-width: $viewport-mobile) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 40px;
  }

  &:last-child {
    padding-right: 0;
  }
}

.info-celled-item__title {
  min-height: 75px;
  margin-bottom: 40px;
  font-family: Visuelt Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 1.357142857;
  color: #000000;

  @media (max-width: $viewport-tablet) {
    font-size: 1.4375rem;
    margin-bottom: 30px;
  }

  @media (max-width: $viewport-mobile) {
    margin-bottom: 20px;
    min-height: 0;
  }
}

.info-celled-item__description {
  margin-bottom: 50px;
  font-style: normal;
  font-weight: normal;
  font-size: 1.0625rem;
  line-height: 2.176470588;
  color: #000000;

  @media (max-width: $viewport-tablet) {
    font-size: 0.875rem;
    margin-bottom: 30px;
  }

  @media (max-width: $viewport-mobile) {
    margin-bottom: 20px;
  }
}

.info-celled-item__list {

}

.info-celled-item-list {

}

.info-celled-item-list__item {
  position: relative;
  padding-left: 25px;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  color: #000000;

  @media (max-width: $viewport-mobile) {
    margin-bottom: 15px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    width: 12px;
    height: 12px;
    background: transparent;
    border: 1px solid #000;
    box-sizing: border-box;
    border-radius: 50%;
  }
}

</style>
